/* Custom Styles */

.full-height {
  height: 100vh;
}

.handyy-container {
  margin-top: 18px;
  height: 88vh;
  padding-top: 60px;
}

.handyy-container-background {
  /* margin-top: 18px; */
  padding-top: 60px;
  /* background: url("../../../App/assets/images/bg-bottom.jpg"); */
  background-repeat: no-repeat;
  background-position: right;
  background-color: white;
  /* height: 100vh; */
  background-size: contain;
}

.handyy-header {
  min-height: 56px;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 0.9rem;
  margin-bottom: 0;
  background-color: #1c2260 !important;
}
.handyy-logo {
  max-height: 70px;
  padding: 10px;
}

.handyy-sidebar {
  position: fixed;
  top: 56px;
  bottom: 0;
  width: 260px;
  transition: transform 0.2s ease;
  background-color: #c50017;
  left: 0;
  z-index: 4;
  /* transform: translate3d(-100%, 0, 0); */
}
.has-drawer-opened {
  transform: translate3d(0, 0, 0) !important;
}

.sidebar-custom-col-3 {
  flex: 0 0 20%;
  max-width: 20%;
}

.handyy-custom-main-col {
  flex: 0 0 80%;
  max-width: 80%;
}

.handyy-custom-main-col-without-sidebar {
  padding-left: 180px;
  padding-right: 180px;
}

.custom-mb1 {
  margin-bottom: 1rem;
  padding: 0 0;
  list-style: none;
}
.sidebar-heading:first-child {
  margin-top: 1.25rem;
}
.sidebar-heading {
  color: white;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 1rem;
  font-family: inherit;
  text-transform: capitalize;
  letter-spacing: 0.92px;
  line-height: normal;
  padding-left: 1.7rem;
  padding-right: 1.7rem;
  padding-top: 1rem;
}

.sidebar-menu-text {
  padding: 0 1.2rem;
}

.sidebar-menu-button {
  color: #ffffff;
  font-weight: 500;
  display: flex;
  align-items: center;
  line-height: 42px;
  padding: 0 1.6rem;
  font-size: 0.925rem;
}

.sidebar-menu-icon {
  color: #ffffff;
  vertical-align: middle;
  font-size: 24px;
  display: inline-block;
  line-height: normal;
  position: relative;
}

.sidebar-menu-icon--left {
  margin-right: 0.625rem;
}

.sidebar-p-a {
  padding: 1rem 1rem;
}

.handyy-primary-button {
  background-color: #6774df;
  border-color: #6774df;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  border: 1px solid transparent;
  padding: 0.375rem 0.875rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-weight: 600;
  color: white;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  width: 100%;
}

.breadcrumb-home-icon {
  font-size: 1.25rem !important;
  position: relative;
  top: -2px;
}

.breadcrumb-active {
  color: rgba(54, 76, 102, 0.54) !important;
  padding-left: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.85px;
  font-size: 0.625rem;
  font-weight: 600;
}

.breadcrumb {
  background-color: transparent;
  margin: 0;
  padding: 0;
  align-items: center;
}

.breadcrumb-item a {
  color: #364c66;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: rgba(54, 76, 102, 0.54);
}

h1 {
  line-height: 1;
  font-weight: 700;
  font-size: 2.34375rem;
  color: rgba(17, 43, 74, 0.84);
}

h5 {
  font-size: 1.171875rem;
}

.item-flex-start {
  flex-direction: column;
}

.item-flex-center {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.sidebar-menu-button:hover {
  color: #212121;
  background: transparent;
  text-decoration: none;
}

.handyy-search-form {
  background-color: rgba(0, 0, 0, 0.24);
  border-color: transparent;
  margin-left: 1rem;
  box-shadow: inset 0 1px 2px 0 rgba(66, 71, 112, 0.12);
  border-radius: 0.25rem;
  flex: 1 1 0% !important;
}

.handyy-searchbar,
.handyy-searchbar:focus {
  border-color: transparent !important;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.84);
  box-shadow: none;
  padding-left: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
}

.handyy-searchbar::placeholder {
  color: #66768a;
  font-weight: 500;
}

.search-icon {
  font-size: 18px !important;
  vertical-align: middle;
  position: relative;
  top: -1px;
  color: rgba(255, 255, 255, 0.54);
}

.handy-search-btn,
.handy-search-btn:hover {
  border-color: transparent;
  background-color: transparent;
}

.avatar {
  flex-shrink: 0;
  font-size: 1rem;
  display: inline-block;
  width: 3rem;
  height: 3rem;
  position: relative;
  z-index: 0;
}

.avatar-img {
  /* width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover; */
  width: 26px;
  height: 26px;
  margin-top: 5px;
}

.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 1px;
}

.pcm-search {
  border: none;
  /* border-color: #DBE5EE; */
  /* box-shadow: inset 0 1px 2px 0 rgba(66, 71, 112, 0.12); */
}

.pcm-search:hover {
  background-color: transparent;
  border-color: #dbe5ee;
}

@media (max-width: 1225px) {
  .handyy-custom-main-col {
    padding-left: 60px;
  }
}

.mdk-drawer__scrim {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: opacity 0.2s ease;
  transform: translateZ(0);
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  z-index: 3;
}

.mdk-drawer__scrim_opacity {
  opacity: 1;
  visibility: visible;
}

.carousel {
  width: 100%;
  margin: auto;
}

/* .carousel-inner {
  height: 500px;
} */
.navDropdown {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: 0 0 !important;
}

.dropdown-toggle::after {
  display: none;
}

#navBarIcons {
  width: 20px;
  margin-right: 5px;
}

#dropdownMenu {
  overflow-y: hidden !important;
  left: -31px;
  top: 54px;
}

.abbr-name {
  display: inline-block;
  width: 7.6ch;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: middle;
}

.dd-right {
  margin-right: -35px;
}

/* file-dropzone styling */

.dropzone.dz-clickable {
  cursor: pointer;
}

.dropzone {
  position: relative;
  display: flex;
  flex-direction: column;
}

.w-100 {
  width: 100% !important;
}

.dropzone.dz-clickable .dz-message {
  cursor: pointer;
}

.dropzone .dz-message {
  text-align: center;
  padding: 2rem 1rem;
  color: #95aac9;
  border: 1px dashed #e2e8ee;
  border-radius: 0.25rem;
  background-color: white;
  order: -1;
}

.dz-default.dz-message:focus {
  outline: none;
}

/* Calendar Styling */

.fc-button-primary {
  color: #fff !important;
  background-color: #6774df !important;
  border-color: #6774df !important;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}

.fc-button-primary:hover {
  background-color: #3d4dd6 !important;
}

.fc-button-primary:not(:disabled):not(.disabled):active,
.fc-button-primary:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #3d4dd6;
  border-color: #3244d4;
}

.fc .fc-button-primary:not(:disabled).fc-button-active:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(126, 137, 228, 0.5) !important;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: #dff6f2;
  border-color: #d2f2ec;
}

.fc-h-event .fc-event-main {
  font-weight: 500;
}

.fc-h-event .fc-event-main i {
  font-style: normal;
}
.fc .fc-scrollgrid-section-body table tr {
  height: 59px;
  min-height: 4em;
}

.search-label {
  margin: 1rem;
  display: block;
}

hr {
  display: none;
}

td.react-bs-table-no-data {
  text-align: center;
  font-size: 24px;
  padding: 1rem;
}
.react-bootstrap-table-pagination {
  margin: 0;
}
.react-bootstrap-table-pagination-list {
  display: flex;
  justify-content: flex-end;
}

.react-bootstrap-table th {
  border: none;
}

.flatpickr-input,
.flatpickr-input:focus {
  border: none;
  margin-bottom: 1rem;
  outline: none;
}

.form-control[readonly] {
  background-color: #fafbfe;
}

#cards-moves-left,
#cards-moves-right {
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}
.current-plan {
  text-align: center;
  display: block;
  padding: 8px;
  font-size: 0.875rem;
  color: white;
}

.basic-plan {
  padding: 8px;
  font-size: 0.875rem;
  display: block;
}

.media-body,
.citation-desc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-paginate {
  width: 130px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.stories-cards th {
  border-bottom: none !important;
}
.stories-cards td {
  border-top: none;
  padding: 0;
}

.stories-cards #pageDropDown {
  background: #fafbfe;
  border: 1px solid #dbe5ee;
  box-shadow: none;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
}

@media (min-width: 385px) {
  .account-phone-input {
    width: 270px;
  }
}

@media (max-width: 992px) {
  .handyy-custom-main-col {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 15px;
  }
  .handyy-sidebar {
    transform: translate3d(-100%, 0, 0);
  }
  .dd-right {
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .sidebar-heading:first-child {
    margin-top: 3rem;
  }
}

@media (min-width: 576px) {
  .nav-item {
    height: 56px;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 575px) {
  .sidebar-heading:first-child {
    margin-top: 1.25rem;
  }
  .fc .fc-toolbar {
    flex-direction: column;
  }
  .carousel-inner {
    height: 300px;
  }
}

@media (max-width: 425px) {
  .col-without-sidebar {
    padding: 0 !important;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .stories-card-overflow {
    overflow: hidden;
  }
  .carousel-inner {
    height: 300px;
  }
  .login-logo,
  .login-google {
    border-radius: 0 !important;
  }
}

@media (max-width: 345px) {
  .handyy-custom-main-col {
    padding: 0;
    padding-top: 20px;
  }
  .sidebar-heading:first-child {
    margin-top: 3rem;
  }
  .carousel-inner {
    height: 300px;
  }
}

@media (min-width: 992px) {
  .card {
    margin-bottom: 30px;
  }
}

.layout-login-centered-boxed {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-process-loader {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background: #00000082; */
}
.login-spinner {
  width: 3rem;
  height: 3rem;
  /* color: white; */
}

/* pdf */
.d-flex {
  display: flex;
  justify-content: space-between;
}
.invoice-logo {
  text-align: right;
}
.invoice-header {
  /* margin-top: 55px; */
}
.customHr {
  margin: 0;
  border-color: black;
  border-width: 2px;
  display: block;
}
.bill-details {
  margin-top: 20px;
}
.mt-1 {
  margin-top: 1rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.product-details {
  margin-top: 75px;
}
.terms_condition {
  list-style: none;
  padding-left: 1.46rem;
}
.terms_condition li {
  position: relative;
  padding-left: 1.466rem;
  line-height: 1.3;
}
.terms_condition li::after {
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  background-color: #6774df;
  border-radius: 100%;
  left: 0;
  top: 0.3rem;
  position: absolute;
}
thead th {
  border-bottom: 2px solid #e2e8ee;
  font-size: 0.825rem;
  color: rgba(55, 77, 103, 0.54);
  vertical-align: middle;
}
tbody tr td {
  font-size: 15px;
  color: #112b4a;
}
.footer {
  position: absolute;
  bottom: 0;
}

.fa-google {
  background: conic-gradient(
      from -45deg,
      #ea4335 110deg,
      #4285f4 90deg 180deg,
      #34a853 180deg 270deg,
      #fbbc05 270deg
    )
    73% 55%/150% 150% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}
.citation-small {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media (max-width: 1400px) {
  .citation-small {
    width: 965px;
  }
}
@media (max-width: 1300px) {
  .citation-small {
    width: 840px;
  }
}
@media (max-width: 1024px) {
  .citation-small {
    width: 580px;
  }
}
@media (max-width: 425px) {
  .citaion-card-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .citation-search-form {
    width: 90%;
    margin-top: 30px;
  }
  .handyy-sidebar {
    width: 310px;
  }
  .sidebar-menu-button {
    padding: 0 2rem;
  }
  .sidebar-menu-text {
    padding: 0 1rem;
  }
  .sidebar-heading {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .react-bootstrap-table-page-btns-ul {
    margin-top: 25px;
  }
  .documenting-search-form {
    width: 100%;
    margin-bottom: 8px;
  }
  .pricing__features li::after {
    left: -18px;
  }
  .pricing__features li {
    position: relative;
  }
  .navbar-brand {
    margin-left: 1rem;
    margin-right: 0;
  }
}
.carousel-inner {
  height: auto;
}

@media (min-width: 320px) {
  .pricing__card--popular .pricing__card-badge {
    background-color: #ee4059;
    color: white;
    padding: 0.2rem 0.66rem;
    border-radius: 0.2rem 0.2rem 0 0;
    font-weight: 700;
    font-size: 0.66rem;
    font-style: italic;
    letter-spacing: 1px;
    line-height: 0.8rem;
    position: absolute;
    top: -1.2rem;
    right: 1.2rem;
    display: inline-block;
    text-transform: uppercase;
  }
  .pricing__amount {
    font-size: 3.4rem;
    line-height: 4.2rem;
    font-weight: 600;
    margin-right: 0.466rem;
  }
  .pricing__features li::after {
    content: "✔";
    /* width: 0.5rem; */
    /* height: 0.5rem; */
    /* background-color: #6774df; */
    color: #6774df;
    /* border-radius: 100%; */
    /* left: 0; */
    top: 1px;
    position: absolute;
  }
}

#dropdownMenu a:hover {
  background-color: #6774df;
  color: white;
}
div#dropdownMenu a {
  transition: all 0.2s linear;
}

@media (min-width: 426px) {
  .citation-search-bar {
    width: 350px !important;
  }
}

@media (min-width: 1025px) {
  .documenting-search-bar {
    width: 350px;
  }
}
.purchase-btn {
  margin: 0 auto;
  padding: 0.375rem 2rem;
}

.active-plan-badge {
  margin: 0 auto;
  padding: 10px 2rem;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
  outline: none;
}
