@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');

body { 
    height: 100%;
    position: relative;
    line-height: 1.25rem; 
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-size: 0.9375rem;
    font-weight: 400;
    /* line-height: 1.5; */
    color: #112b4a;
    text-align: left;
    background-color: #fafbfe;
    /* background: url("./App/assets/images/bg-bottom.jpg") no-repeat right; */
}
